/* You can add global styles to this file, and also import other style files */

.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.4rem 1rem !important;
}

.mat-sidenav-container,
.mat-sidenav-content,
.mat-tab-body-content {
    transform: none !important;
}

// This will fix image slider position
.mat-toolbar.mat-primary {
    z-index: 0 !important;
}

* {
    touch-action: manipulation;
}

.selection-row {
    cursor: pointer;
}

@-webkit-keyframes invalid {
    from {
        background-color: rgb(255, 230, 149);
    }
    to {
        background-color: inherit;
    }
}
@-moz-keyframes invalid {
    from {
        background-color: rgb(255, 230, 149);
    }
    to {
        background-color: inherit;
    }
}
@-o-keyframes invalid {
    from {
        background-color: rgb(255, 230, 149);
    }
    to {
        background-color: inherit;
    }
}
@keyframes invalid {
    from {
        background-color: rgb(255, 230, 149);
    }
    to {
        background-color: inherit;
    }
}
.mark-table-row {
    -webkit-animation: invalid 5s; /* Safari 4+ */
    -moz-animation: invalid 5s; /* Fx 5+ */
    -o-animation: invalid 5s; /* Opera 12+ */
    animation: invalid 5s; /* IE 10+ */
}

.weet-je-dit-zeker-modal-warning {
    color: red !important;
    font-weight: bold;
}
