/*******************
 Topbar
*******************/
mat-toolbar {
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);
  height: 64px; /* Set a consistent height for the toolbar */

  &.topbar {
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fix-width-for-horizontal {
    display: flex;
    align-items: center;
    width: 100%; /* Ensure it stretches across the entire toolbar */
  }

  .sidebar-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; /* Ensure it takes the full height of the toolbar */
    margin-right: 15px; /* Optional: add some space between the button and the logo */
  }

  .navbar-header {
    text-align: center;
    display: flex;
    align-items: center;

    .navbar-brand {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 45px;
        width: auto; /* Keep the aspect ratio of the logo */
        display: block;
        margin: 0; /* Remove any default margin or padding */
      }
    }
  }

  .search-form {
    display: flex;
    align-items: center;
    max-width: 300px;
    flex-grow: 1;
    margin-left: auto; /* Separate it from the logo and align to the right */

    .search-field {
      padding-top:18px;
      width: 100%;
      margin: 0;
    }

    mat-form-field {
      margin: 0;
      width: 100%;
    }

    button {
      margin-left: -40px; /* Align the button to be inside the input */
    }
  }
}
