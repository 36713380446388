// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "@ng-matero/extensions" as mtx;
@use "spinner";
@use "app";
@use "header";
@use "pages";
@use "sidebar";
@use "responsive";

@import "icons/themify-icons/themify-icons.css";
@import "icons/font-awesome/css/font-awesome.css";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define a complete primary palette using your custom color
$custom-primary-palette: mat.m2-define-palette(
  (
    50: #e0f7fa,
    100: #b2ebf2,
    200: #80deea,
    300: #4dd0e1,
    400: #26c6da,
    500: #4596c7,
    // Your primary color
    600: #00acc1,
    700: #0097a7,
    800: #00838f,
    900: #006064,
    contrast: (
      500: #ffffff // White text on primary color
    )
  )
);

// Define a complete accent palette using your custom color
$custom-accent-palette: mat.m2-define-palette(
  (
    50: #fce4ec,
    100: #f8bbd0,
    200: #f48fb1,
    300: #f06292,
    400: #ec407a,
    500: #42b7a2,
    // Your accent color
    600: #d81b60,
    700: #c2185b,
    800: #ad1457,
    900: #880e4f,
    contrast: (
      500: #ffffff // White text on accent color
    )
  )
);

// Define a complete warn palette using your custom color
$custom-warn-palette: mat.m2-define-palette(
  (
    50: #ffebee,
    100: #ffcdd2,
    200: #ef9a9a,
    300: #e57373,
    400: #ef5350,
    500: #e8518c,
    // Your warn color
    600: #e53935,
    700: #d32f2f,
    800: #c62828,
    900: #b71c1c,
    contrast: (
      500: #ffffff // White text on warn color
    )
  )
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$admin-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $custom-primary-palette,
      accent: $custom-accent-palette,
      warn: $custom-warn-palette
    ),
    typography: mat.m2-define-typography-config(),
    density: 0
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($admin-theme);
  @include mtx.all-component-themes($admin-theme);
}
