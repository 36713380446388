/*******************
 Main container
*******************/

.page-breadcrumb {
    background: white !important;
  }

.main-container {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mat-drawer-container {
    background: #eef5f9 !important;
}

html .page-wrapper {
    height: calc(100vh - 64px);
    .page-content {
        padding: 10px;
    }
}

/*******************
 Login register
*******************/
.login-register {
    height: 100vh;
    display: flex;
    background-size: cover;
    align-items: center;
    .mat-mdc-card {
        margin: 0px 15px;
    }
    .mat-mdc-form-field {
        width: 100%;
    }
    .login-register-box {
        max-width: 400px;
        min-width: 280px;
        margin: 0 auto;
        vertical-align: middle;
    }
    .support-text {
        margin-top: -15px;
        display: block;
        padding-bottom: 15px;
    }
}
.error-card {
    h1 {
        font-size: 210px;
        font-weight: 900;
        line-height: 210px;
        margin: 0px;
        text-shadow: 4px 5px 1px #b3bbbf;
    }
}

.act {
    color: white;

    &:hover {
        color: aqua;
    }
}

.mdc-data-table__row {
    height: 25px !important;
}

.mdc-data-table__cell {
    font-size: 12px !important;
    cursor: pointer;
}

.b2burgent {
    color: black !important;
    background-color: rgb(253, 161, 161) !important;

    a {
        color: blue !important;
    }
}

.b2burgent:hover {
    background-color: rgb(255, 200, 200) !important;
}

.c2burgent:hover {
    background-color: rgb(255, 243, 232) !important;
}

.b2burgent {
    color: black !important;
    background-color: rgb(253, 161, 161) !important;

    a {
        color: blue !important;
    }
}

mtx-grid .mat-sort-header-icon {
    display: none !important;
}
