@use './variable' as vars;

$mini-sidebar-width: 60px;
/*******************
Main sidebar
******************/

.mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  display: inline-block !important;
  vertical-align: middle !important;
}

.mat-mdc-chip {
  position: relative;
  vertical-align: inherit;
}

mat-sidenav {
  width: 250px !important;
  padding-top: 0px;
}

#snav {
  box-shadow: 5px 0px 200px rgba(0, 0, 0, 0.08);
  .mat-mdc-list-item {
    height: auto;
    &:hover {
      background: none;
    }
    .mat-mdc-list-item-unscoped-content {
      display: block;
      padding: 0px 8px;
      &:hover {
        background: none;
        > a {
          color: vars.$themecolor;
        }
      }
    }

    a {
      height: 45px;
      padding: 0 10px;
      margin-bottom: 10px;
      align-items: center;
      display: flex;
      font-size: 14px;
      white-space: nowrap;
      color: vars.$sidebar-text;
      mat-icon:not(.dd-icon) {
        margin-right: 8px;
      }
      mat-icon {
        color: vars.$sidebar-icons;
      }
      .dd-icon {
        font-size: 16px;
        width: 16px;
        transition: 0.2s ease-in;
        margin: 5px 0 5px 5px;
        height: 16px;
      }
    }
    &.selected > .mat-mdc-list-item-unscoped-content > a {
      background: vars.$themecolor-alt;
      border-radius: vars.$radius;
      color: vars.$white;

      mat-icon {
        color: vars.$white;
      }
      .dd-icon {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }
    &.selected .sub-item .mat-mdc-list-item-unscoped-content.selected {
      background: transparent;
      color: vars.$inverse;
      font-weight: 500;
    }
  }
  .sub-item {
    display: none;
    margin-top: -15px;
    a {
      padding-left: 10px;
      height: 40px;
      margin-bottom: 0px;
    }
  }
  .saperator {
    cursor: text;
    font-size: 13px;
    margin-bottom: 15px;
    margin-top: 25px;
    font-weight: 500;
    white-space: nowrap;
  }
  .selected {
    .sub-item {
      display: block;
    }
  }
}
/*******************
use profile section
******************/

.user-profile {
  position: relative;
  background-size: cover;

  .profile-img {
    width: 50px;
    margin-left: 30px;
    padding: 31px 0;
    &::before {
      position: absolute;
      content: "";
      width: 50px;
      height: 50px;
      top: 31px;
      margin: 0 auto;
      z-index: 0;
    }

    img {
      width: 100%;
    }
  }
  .profile-text {
    padding: 5px 0px;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    > a {
      color: vars.$white !important;
      width: 100%;
      padding: 6px 20px;
      background: rgba(0, 0, 0, 0.5);
      display: block;
      white-space: nowrap;
      &:after {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }
}

.sidebar-closed #snav {
  &.mat-sidenav ~ .mat-drawer-content {
    margin-left: 0px !important;
  }
}

#snav .mat-mdc-list-item.active {
  background-color: #e9e9e9 !important; /* Set the background color of the active menu item */
  color: #000000 !important; /* Set the text color of the active menu item */
  font-weight: bold !important; /* Set the font weight of the active menu item */
}

#snav .mdc-list-item {
  padding-left: 5px !important;
  padding-right: 16px;
}